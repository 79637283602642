.cool-progress {
  width: 100%;
  max-width: 260px;
  height: 6px;
  background: #f00;
  border-radius: 3px;
  overflow: hidden;
  .progress-bar {
    display: block;
    height: 100%;
    background: linear-gradient(90deg, #ffd33d, #ea4aaa 17%, #b34bff 34%, #01feff 51%, #ffd33d 68%, #ea4aaa 85%, #b34bff);
    background-size: 300% 100%;
    animation: progress-animation 2s linear infinite;
  }
  @keyframes progress-animation {
    0% {
      background-position: 100%
    }
    100% {
      background-position: 0
    }
  }
}
.notification-list{
  height: 500px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}
::-webkit-scrollbar-track {
  background: #cccccc22;
}
::-webkit-scrollbar-thumb {
  background-color: #ccccccaa;
  border-radius: 20px;
}
.pad-small {
  @media (max-width: 1200px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

