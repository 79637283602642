.ppg-page{
  height: 50vh;
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 14px 2px rgba(0,0,0,0.025);
  -moz-box-shadow: 0 0 14px 2px rgba(0,0,0,0.025);
  box-shadow: 0 0 14px 2px rgba(0,0,0,0.025);
  padding: 16px;
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  ::-webkit-scrollbar-track {
    background: #cccccc22;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ccccccaa;
    border-radius: 20px;
    transition: 0.5s;
  }
  .flex{
    height: 100%;
  }
  .flex2{
    height: 100%;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
  }
  .scrollable{
    position: relative;
    flex-shrink: 1;
    //background-color: red;
  }
  .aligned-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
  }
  .thirdparty-logo{
    width: 24px;
    margin-right: 5px;
    margin-left: 8px;
  }
}

.link-holder{
  background-color: #D2EDDB;
  border-radius: 12px;
  border: solid 1px #C0E6CC;
  padding: 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  strong{
    color: #155724;
  }
  i{
    color: #155724;
    cursor: pointer;
  }
}
