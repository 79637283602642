@font-face {
    font-family: 'cairo';
    src: url('/src/assets/fonts/Cairo.ttf') format('truetype');
}

body {
    font-family: cairo;
}

.chrome-install-pop {
    display: none;
    z-index: 999999;
    border-radius: 8px;
    background-color: #FFF;
    box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.08),0px 16px 24px 2px rgba(0,0,0,0.04),0px 6px 30px 5px rgba(0,0,0,0.05);
    width: 328px;
    padding: 16px;
    position: fixed;
    bottom: 77px;
    left: 16px
}

.chrome-install-pop .chrome-install-pop-content {
    display: flex;
    gap: 8px;
}

.chrome-install-pop .chrome-install-pop-content img {
    width: 56px;
    height: 56px
}

.chrome-install-pop .chrome-install-pop-content .info {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.chrome-install-pop .chrome-install-pop-content .info .title {
    color: #5E5E5E;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 24px
}

.chrome-install-pop .chrome-install-pop-content .info .content {
    display: flex;
    flex-direction: column;
    gap: 12px
}

.chrome-install-pop .chrome-install-pop-content .info .content .description {
    font-size: 15px;
    line-height: 20px;
    color: #8B8B8B
}

.chrome-install-pop .chrome-install-pop-content .info .content .install {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background-color: #4087F7;
    padding: 6px 16px;
    color: #FFF
}

.chrome-install-pop .chrome-install-pop-content .info .content .install:hover {
    opacity: .9
}

.chrome-install-pop .chrome-pop-close {
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.chrome-install-pop .chrome-pop-close::before,.chrome-install-pop .chrome-pop-close::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    background-color: #8B8B8B
}

.chrome-install-pop .chrome-pop-close::before {
    transform: rotate(45deg)
}

.chrome-install-pop .chrome-pop-close::after {
    transform: rotate(-45deg)
}

.chrome-install-pop .chrome-pop-close:hover::before,.chrome-install-pop .chrome-pop-close:hover::after {
    background-color: #5E5E5E
}

.ar_fix .chrome-install-pop {
    right: 16px
}

.ar_fix .chrome-install-pop .chrome-install-pop-content .info .title {
    margin-right: initial;
    margin-left: 24px
}

.ar_fix .chrome-install-pop .chrome-pop-close {
    right: auto;
    left: 16px
}

.hAYaRF {
    position: relative;
    background: #282a36;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
}

.bFNYQa {
    position: absolute;
    top: 0.5em;
    right: 0.75em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #282a36;
    margin-top: 0.15rem;
    border-radius: 0.25rem;
    max-height: 2rem;
    max-width: 2rem;
    padding: 0.25rem;
}